import { graphql } from "gatsby";
import React, { FC } from "react";
import BlogsMain from "../components/BlogUiComponents/BlogsMain/BlogsMain";
import { UseTagsFilter } from "../components/hooks/UseTagsFilter";
import Layout from "../components/Layout/Layout";
import { SEO } from "../components/seo";

type Props = {
  data: any;
  pageContext: any;
  location: any;
};

const NewsList: FC<Props> = ({ data, pageContext, location }) => {
  const { currentPage, newsPaginationNumber, blogs } = pageContext;
  const selectedTags = UseTagsFilter("news", blogs, data.allSanityTag.nodes);

  return (
    <div style={{ color: "white" }}>
      <Layout location={"/blog"}>
        <SEO
          title="Company news"
          description="Follow our journey and be the first to know about our exciting partnerships , plans and community news."
          type="website"
        />
        <>
          <BlogsMain
            blogName={"/news"}
            basePathForUrl={"/news"}
            data={data.newsBlogs.edges}
            tags={selectedTags} //sanity
            numberPages={newsPaginationNumber}
            currentPage={currentPage}
          />
        </>
      </Layout>
    </div>
  );
};

export default NewsList;

export const blogListQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    newsBlogs: allSanityBlog(
      limit: $limit
      skip: $skip
      filter: {
        blogType: { elemMatch: { title: { eq: "news" } } },
        tags: { elemMatch: { title: { ne: "DEUTSCH" } } }
      }
      sort: { fields: publishedAt, order: DESC }
    ) {
      edges {
        node {
          id
          subtitle
          title
          blogType {
            title
          }
          _rawBody
          _rawMainImage(resolveReferences: { maxDepth: 20 })
          body {
            children {
              text
            }
          }
          tags {
            title
          }
          mainImage {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
          alt
          publishedAt
        }
      }
      pageInfo {
        totalCount
      }
    }
    allSanityTag{
      nodes {
        title
      }
    }
  }
`;
